import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
    headTitle1?: string;
    headTitle2?: string;
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    children?: Menu[];
    modulo?: number;
    seccion?: number;
}

@Injectable({
    providedIn: "root",
})
export class NavService implements OnDestroy {
    private unsubscriber: Subject<any> = new Subject();
    public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

    // Search Box
    public search: boolean = false;

    // Language
    public language: boolean = false;

    // Mega Menu
    public megaMenu: boolean = false;
    public levelMenu: boolean = false;
    public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

    // Collapse Sidebar
    public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

    // For Horizontal Layout Mobile
    public horizontal: boolean = window.innerWidth < 991 ? false : true;

    // Full screen
    public fullScreen: boolean = false;

    constructor(
        private router: Router
    ) {
        this.setScreenWidth(window.innerWidth);
        fromEvent(window, "resize")
        .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
        .subscribe((evt: any) => {
            this.setScreenWidth(evt.target.innerWidth);
            if (evt.target.innerWidth < 991) {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
            }
            if (evt.target.innerWidth < 1199) {
                this.megaMenuColapse = true;
            }
        });
        if (window.innerWidth < 991) {
            // Detect Route change sidebar close
            this.router.events.subscribe((event) => {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
            });
        }
    }

    ngOnDestroy() {
        // this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    private setScreenWidth(width: number): void {
        this.screenWidth.next(width);
    }

    MENUITEMS: Menu[] = [
        {
            path: "/app/inicio",
            title: "Inicio",
            icon: "icofont-home",
            type: "link",
            bookmark: true,
            active: true,
        },
        {
            title: "Operaciones Portuarias",
            icon: "icofont-chart-flow",
            type: "sub",
            active: false,
            modulo: 1,
            children: [
                { 
					path: '/app/oportuarias/operaciones',
					title: 'Operaciones',
					type: 'link',
					seccion: 17
				},
                { 
					path: '/app/oportuarias/trackings',
					title: 'trackings',
					type: 'link',
					seccion: 15
				},
                { 
					path: '/app/oportuarias/alertas',
					title: 'Alertas',
					type: 'link',
					seccion: 16
				},
                {
					path: '/app/oportuarias/documentos',
					title: 'Documentos',
					type: 'link',
					seccion: 4
				}
            ]
        },
        {
            title: "Maestros",
            icon: "icofont-listing-box",
            type: "sub",
            active: false,
            modulo: 2,
            children: [
                {
					path: '/app/maestros/personal',
					title: 'Personal',
					type: 'link',
					seccion: 18
				},
                {
					path: '/app/maestros/clientes',
					title: 'Clientes',
					type: 'link',
					seccion: 6
				},
                {
					path: '/app/maestros/consignantes',
					title: 'Consignantes',
					type: 'link',
					seccion: 7
				},
                {
					path: '/app/maestros/companias-transporte',
					title: 'Compañías de Transporte',
					type: 'link',
					seccion: 8
				},
                {
					path: '/app/maestros/emisores-documentos',
					title: 'Emisores de Documentos',
					type: 'link',
					seccion: 9
				},
                {
					path: '/app/maestros/aduanas',
					title: 'Aduanas',
					type: 'link',
					seccion: 19
				},
                {
					path: '/app/maestros/naves',
					title: 'Naves',
					type: 'link',
					seccion: 10
				},
                {
					path: '/app/maestros/puertos',
					title: 'Puertos',
					type: 'link',
					seccion: 11
				},
                {
					path: '/app/maestros/empresas-transporte',
					title: 'Empresas de Transporte',
					type: 'link',
					seccion: 12
				},
                {
					path: '/app/maestros/choferes',
					title: 'Choferes',
					type: 'link',
					seccion: 13
				}
            ]
        },
        {
            title: "Centro de Usuarios",
            icon: "icofont-users",
            type: "sub",
            active: false,
            modulo: 3,
            children: [
                { 
					path: '/app/centro-usuarios/usuarios',
					title: 'Usuarios',
					type: 'link',
					seccion: 1
				},
				{ 
					path: '/app/centro-usuarios/bitacora',
					title: 'Bitácora',
					type: 'link',
					seccion: 2
				},
                { 
					path: '/app/centro-usuarios/usuarios-externos',
					title: 'Usuarios Externos',
					type: 'link',
					seccion: 3
				}
            ]
        },
        {
            title: "Configuraciones",
            icon: "icofont-options",
            type: "sub",
            active: false,
            modulo: 4,
            children: [
                {
					path: '/app/configuraciones/empresas',
					title: 'Empresas',
					type: 'link',
					seccion: 5
				},
                {
					path: '/app/configuraciones/parametros',
					title: 'Parámetros',
					type: 'link',
					seccion: 14
				}
            ]
        }
    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
