import { Component, OnInit } from "@angular/core";

declare var require;
const Swal = require('sweetalert2');

import { AccountService } from '@services/account.service';

@Component({
    selector: "app-my-account",
    templateUrl: "./my-account.component.html",
    styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
    name: string;
    userName: string;
    tipo: number;
    profileImg: "assets/images/dashboard/profile.jpg";

    constructor(
        private accountService: AccountService
    ) {
        let account = this.accountService.accountValue;

        this.name = account.name;
        this.userName = account.username;
        this.tipo = account.tipo;
    }

    ngOnInit() {}

    logout() {
        Swal.fire({
            icon: 'question',
            title: 'Cerrar Sesión',
            text: 'Seguro de cerrar sesión.',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        })
        .then((result) => {
            if (result.value) {
                this.accountService.logout();
            }
        });
    }
}
