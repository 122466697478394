import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Account } from '@models/account';

const API_URL = `${environment.apiUrl}`;
const USER_KEY = 'Sesnich-TW-User';
const PERM_KEY = 'Sesnich-TW-PermUser';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;

    public get getPermisos() {
        return JSON.parse(atob(sessionStorage.getItem(PERM_KEY)));
    }

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.accountSubject = new BehaviorSubject<Account>(JSON.parse(sessionStorage.getItem(USER_KEY)));
        this.account = this.accountSubject.asObservable();
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${API_URL}/login`, { username, password })
            .pipe(map(resp => {
                if (resp.token) {
                    let account = {
                        id: resp.user.id,
                        name: resp.user.name,
                        username: resp.user.username,
                        email: resp.user.email,
                        tipo: resp.user.tipo,
                        jwtToken: resp.token
                    };
                    sessionStorage.setItem(USER_KEY, JSON.stringify(account));
                    sessionStorage.setItem(PERM_KEY, resp.permisos);
                    this.accountSubject.next(account);
                    // this.startRefreshTokenTimer();
                }

                return resp.message;
            }));
    }

    logout() {
        this.http.get<any>(`${environment.apiUrl}/logout`).subscribe();
        // this.stopRefreshTokenTimer();
        sessionStorage.removeItem(USER_KEY);
        sessionStorage.removeItem(PERM_KEY);
        this.accountSubject.next(null);
        this.router.navigate(['/auth/login']);
    }

    refreshToken() {
        return this.http.post<any>(`${API_URL}/refresh-token`, {})
            .pipe(map((account) => {
                // this.accountSubject.next(account);
                // this.startRefreshTokenTimer();
                return account;
            }));
    }

    register(account: Account) {
        return this.http.post(`${API_URL}/register`, account);
    }

    verifyEmail(token: string) {
        return this.http.post(`${API_URL}/verify-email`, { token });
    }
    
    forgotPassword(email: string) {
        return this.http.post(`${API_URL}/forgot-password`, { email });
    }
    
    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${API_URL}/reset-password`, { token, password, confirmPassword });
    }

    // helper methods

    /* private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtBase64 = this.accountValue!.jwtToken!.split('.')[1];
        const jwtToken = JSON.parse(atob(jwtBase64));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    } */

    public esAdministrador() {
        if (this.accountValue.tipo == 2)
            return true;
        else
            return false;
    }

    public accesoModulos(modulo?: number): any[] {
        let datos = [];
        let accesos: any = this.getPermisos;
        
        if (modulo) {// retorno solo el modulo enviado
            accesos.forEach( (item, indice) => {
                let arrModulo = [];

                if (item.id == modulo) {
                    arrModulo['id'] = item.id;
                    arrModulo['nombre'] = item.nombre;
                    arrModulo['checked'] = (item.checked) ? true : false;

                    datos = arrModulo;
                }
            });
        } else {
            accesos.forEach( (item, indice) => {
                let arrModulo = [];

                arrModulo['id'] = item.id;
                arrModulo['nombre'] = item.nombre;
                arrModulo['checked'] = (item.checked) ? true : false;

                datos.push(arrModulo);
            });
        }

        return datos;
    }

    public accesoSecciones(seccion?: number): any[] {
        let datos = [];
        let accesos: any = this.getPermisos;

        if (seccion) {// retorno solo la seccion enviada
            accesos.forEach( (item, indice) => {
                item.secciones.forEach( (sitem, sindice) => {
                    let arrSeccion = [];

                    if (sitem.id == seccion) {
                        arrSeccion['id'] = sitem.id;
                        arrSeccion['nombre'] = sitem.nombre;
                        arrSeccion['checked'] = (sitem.checked) ? true : false;

                        datos = arrSeccion;
                    }
                });
            });
        } else {
            accesos.forEach( (item, indice) => {
                item.secciones.forEach( (sitem, sindice) => {
                    let arrSeccion = [];

                    arrSeccion['id'] = sitem.id;
                    arrSeccion['nombre'] = sitem.nombre;
                    arrSeccion['checked'] = (sitem.checked) ? true : false;

                    datos.push(arrSeccion);
                });
            });    
        }

        return datos;
    }

    public permisosSecciones(seccion: number, permiso?: number): any[] {
        let datos = [];
        let accesos: any = this.getPermisos;

        if (permiso) {// retorno solo el permiso enviado
            accesos.forEach( (item, indice) => {
                item.secciones.forEach( (sitem, sindice) => {
                    if (sitem.id == seccion) {
                        sitem.permisos.forEach( (pitem, pindice) => {
                            let arrPermiso = [];

                            if (pitem.id == permiso) {
                                arrPermiso['id'] = pitem.id;
                                arrPermiso['nombre'] = pitem.nombre;
                                arrPermiso['checked'] = (pitem.checked) ? true : false;

                                datos = arrPermiso;
                            }
                        });
                    }
                });
            });
        } else {
            accesos.forEach( (item, indice) => {
                item.secciones.forEach( (sitem, sindice) => {
                    if (sitem.id == seccion) {
                        sitem.permisos.forEach( (pitem, pindice) => {
                            let arrPermiso = [];

                            arrPermiso['id'] = pitem.id;
                            arrPermiso['nombre'] = pitem.nombre;
                            arrPermiso['checked'] = (pitem.checked) ? true : false;

                            datos.push(arrPermiso);
                        });
                    }
                });
            });
        }

        return datos;
    }
}